.c-topbar {
  position: fixed;

  align-items: center;

  padding: 1.3rem;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;

  background: $color-white;

  z-index: 99999;

  @include bp(medium) {
    padding: 1.3rem 9rem;
  }
}

.c-topbar__placeholder {
  height: 70px;
  display: block;
}

.c-topbar__logo {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0.85;
  color: $color-black;
  text-decoration: none;
  width: 120px;

  &:hover {
    color: $color-black;
  }
}

.c-topbar__left,
.c-topbar__right {
  display: flex;
  align-items: center;
}

.c-topbar__left {
  justify-content: space-between;
}

.c-topbar__hamburger {
  width: 2rem;
  height: 1.3rem;

  display: flex;
  flex-direction: column;

  margin-left: 0.85rem;

  cursor: pointer;

  span {
    display: block;
    height: 0.3rem;
    width: 100%;
    background: $color-black;
    margin-top: 4px;
    border-radius: 5px;

    &:first-child {
      margin: 0;
    }
  }
}

.c-topbar__icon-round {
  border-radius: 50%;

  background: $color-dark-grey;
}

.c-topbar__lang-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.8rem;
  height: 1.8rem;

  margin-left: 2rem;

  color: $color-white;
  font-size: 0.75rem;
  font-weight: 700;

  text-transform: uppercase;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: $color-white;
  }
}

.c-topbar__social {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.8rem;
  height: 1.8rem;

  margin-left: 0.5rem;

  > img {
    width: 1rem;
    height: 1rem;
  }
}