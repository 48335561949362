/* ==========================================================================
   #LISTS
   ========================================================================== */

li {
  margin-bottom: 0.5rem;
  padding-left: 0.5em;
  text-indent: 0;

  .c-about-us__listing & {
    &::before {
      content: "• ";
      font-size: 2rem;
      vertical-align: middle;
      color: $color-red;
    }
  }

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
