.c-why-us {
  padding: 0 15px 80px;
  background: $color-black;
}

.c-why-us__content {
  @include bp(medium) {
    display: flex;
    justify-content: space-between;
  }
}

.c-why-us__item {
  margin-top: 50px;
  width: 48%;
  margin: 50px auto 0;
}

.c-why-us__icon {
  width: 120px;
  margin: 0 auto;
}

.c-why-us__title {
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 5px;
}

.c-why-us__description {
  color: $color-white;
  text-align: center;
}
