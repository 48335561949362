/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;

  height: 65vh;

  background-image: url('../assets/images/header-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.c-header__box {
  position: absolute;

  text-align: center;

  top: 50%;
  transform: translateY(-50%);

  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 15px;
}

.c-header__title {
  color: $color-white;

  letter-spacing: 3px;

  margin: 0;
  margin-bottom: 0.5rem;

  font-size: 3rem;
}

.c-header__subtitle {
  color: $color-white;

  font-size: 1.1rem;
  text-transform: uppercase;
}