.c-form {
  margin: 50px auto 0;

  @include bp(medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 800px;
    width: 100%;
  }
}

.c-form__group {
  @include bp(medium) {
    width: 49%;
  }
}

.c-form__input {
  margin-top: 2.4rem;

  &:first-child {
    margin-top: 0;
  }

  @include bp(medium) {
    padding-right: 5rem;
  }
  input {
    color: $color-black;
    font-size: 0.8rem;
    font-weight: 700;

    width: 100%;

    padding-bottom: 0.8rem;

    background: transparent;
    border: none;
    border-bottom: 1px solid $color-black;

    &::placeholder {
      color: $color-black;
    }
  }
}

input, textarea {
  outline: none;
}

.c-form__textarea {
  margin-top: 2rem;

  @include bp(medium) {
    margin-top: 0;
  }

  label {
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 700;
    color: $color-black;

    margin-bottom: 0.8rem;
  }

  textarea {
    color: $color-black;

    width: 100%;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 0.5rem;

    resize: none;
    background: transparent;
    border: none;
    border: 1px solid $color-black;
  }
}

.c-form__accept {
  margin-top: 2rem;
}

.c-form__accept-explain {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
  display: block;

  color: $color-black;
  letter-spacing: 1px;
}

.c-form__label {
  display: flex;
  align-items: center;
}

.c-form__checkbox {
  margin-top: 5px;
  color: $color-black;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;

  label {
    margin-left: 3px;
  }

  a {
    color: $color-black;
    font-weight: 700;
    text-decoration: none;
  }
}

input[type="checkbox"] {
  position: absolute;
  z-index: -2222;
  left: -9999999px;
  &:checked + .c-form__label {
    .c-form__real-checkbox {
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 6px;
        height: 6px;

        left: 0;
        right: 0;
        margin: 0 auto;

        top: 50%;
        transform: translateY(-50%);
        background: $color-black;
      }
    }
  }
}

.c-form__send {
  margin-top: 1.5rem;
  background: $color-black;
  border: none;
  color: $color-white;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 0;

  padding: 1rem 4rem;

  text-transform: uppercase;
}

.c-form__real-checkbox {
  display: inline-block;
  position: relative;

  cursor: pointer;

  width: 10px;
  height: 10px;

  margin-right: 5px;

  border: 1px solid $color-faded-grey;
}