@charset "UTF-8";
/*
  Project: AD1990
  Author: Daniel
 */
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap&subset=latin-ext';
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
@import url(/node_modules/simple-lightbox/dist/simpleLightbox.css);
@import url(/node_modules/tachyons/css/tachyons.css);
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #191919;
  font-family: "Montserrat", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  font-weight: 500;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}

a:hover {
  color: #989898;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
  padding-left: 0.5em;
  text-indent: 0;
}

.c-about-us__listing li::before {
  content: "• ";
  font-size: 2rem;
  vertical-align: middle;
  color: #be1010;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-heading {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
}

.o-heading__icon {
  width: 100px;
  display: inline-block;
  margin-bottom: 2rem;
}

.o-heading__title {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.3;
}

@media (min-width: 480px) {
  .o-heading__title {
    font-size: 1.3rem;
  }
}

.o-heading__title--white {
  color: #fff;
}

.o-heading__subheading {
  font-size: 1rem;
  max-width: 600px;
  line-height: 1.6;
  margin: 1rem auto 0;
}

.o-heading__subheading--white {
  color: #fff;
}

.o-heading__subheading--faded {
  color: #545454;
}

.o-heading__subheading--uppercase {
  text-transform: uppercase;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-about-us {
  padding: 0 15px 90px;
}

.c-about-us__content {
  max-width: 1000px;
  margin: 4rem auto 0;
}

.c-about-us__quote {
  line-height: 1.8;
  letter-spacing: 1px;
}

.c-about-us__faksmika {
  margin-top: 2rem;
  width: 300px;
}

.c-about-us__credentials {
  margin-top: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 1px;
}

.c-about-us__referencje {
  max-width: 1000px;
  margin: 4rem auto 0;
}

.c-about-us__referencje-icon {
  display: inline-block;
  width: 100px;
}

.c-about-us__referencje-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.c-about-us__referencje-text {
  display: inline-block;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #191919;
  margin-left: 10px;
  line-height: 0;
  letter-spacing: 1px;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #666;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #989898;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-contact {
  padding: 0 15px 3rem;
}

.c-contact__info-block {
  margin: 70px auto 0;
  max-width: 800px;
}

@media (min-width: 768px) {
  .c-contact__info-block {
    display: flex;
    justify-content: space-between;
  }
}

.c-contact__info-block__item {
  display: flex;
  margin-top: 2rem;
  text-align: center;
}

.c-contact__info-block__item:first-child {
  margin-top: 0;
}

@media (min-width: 480px) {
  .c-contact__info-block__item {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .c-contact__info-block__item {
    margin-top: 0;
  }
}

.c-contact__info-block__icon {
  width: 15px;
  display: none;
}

@media (min-width: 480px) {
  .c-contact__info-block__icon {
    display: block;
  }
}

.c-contact__info-block__info {
  position: relative;
  width: 100%;
}

@media (min-width: 480px) {
  .c-contact__info-block__info {
    width: auto;
    padding-left: 50px;
  }
}

.c-contact__info-block__info:before {
  content: "";
  position: absolute;
  display: none;
  left: 20px;
  height: 100%;
  width: 1px;
  background: #292929;
}

@media (min-width: 480px) {
  .c-contact__info-block__info:before {
    display: block;
  }
}

.c-contact__info-block__title {
  font-size: 0.7rem;
  margin: 0;
  text-transform: uppercase;
}

.c-contact__info-block__link {
  color: #191919;
  font-size: 1.5rem;
  text-decoration: none;
  display: block;
}

@media (min-width: 480px) {
  .c-contact__info-block__link {
    display: inline;
  }
}

.c-contact__info-block__link:nth-child(3) {
  margin-left: 1rem;
}

.c-contact__info-block__link-divider {
  display: none;
}

@media (min-width: 480px) {
  .c-contact__info-block__link-divider {
    display: inline;
  }
}

.c-contact__rodo {
  text-transform: uppercase;
  font-size: 0.8rem;
  max-width: 800px;
  margin: 80px auto 0;
  letter-spacing: 1px;
}

.c-contact__rodo a {
  color: #191919;
  font-weight: 700;
}

.c-contact__social {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 40px;
}

.c-contact__social .c-topbar__social {
  opacity: 0.6;
}

.c-contact__social .c-topbar__social:hover {
  opacity: 0.85;
}

.c-divider {
  height: 200px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .c-divider {
    height: 422px;
  }
}

.c-dron-usage {
  background: #f8f8f8;
  padding: 0 15px;
}

.c-dron-usage__content {
  padding-bottom: 100px;
}

@media (min-width: 480px) {
  .c-dron-usage__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
  }
}

.c-dron-usage__item {
  margin-top: 50px;
}

@media (min-width: 480px) {
  .c-dron-usage__item {
    margin-top: 100px;
    display: flex;
    width: 49%;
  }
}

@media (min-width: 768px) {
  .c-dron-usage__item {
    width: 23%;
  }
}

.c-dron-usage__icon {
  max-width: 80px;
}

@media (min-width: 480px) {
  .c-dron-usage__icon {
    max-width: 40px;
    width: 100%;
  }
}

.c-dron-usage__text {
  margin-top: 25px;
  font-size: 0.9rem;
}

@media (min-width: 480px) {
  .c-dron-usage__text {
    margin-top: 0;
    padding-left: 25px;
  }
}

.c-dron-usage__title {
  font-size: 1.2rem;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-drop-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -100vh;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.95);
  padding-left: 1.5em;
  transition: top 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999999999;
}

.c-drop-menu--show {
  top: 0;
}

.c-drop-menu__logo {
  display: block;
}

.c-drop-menu__wrapper {
  display: block;
  text-align: center;
}

.c-drop-menu__items {
  margin-top: 50px;
}

.c-drop-menu__close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.c-drop-menu__close span {
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 100%;
  transform: rotate(45deg);
  background: #be1010;
  border-radius: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c-drop-menu__close span:first-child {
  transform: rotate(-45deg);
}

.c-drop-menu__item {
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  text-align: center;
  line-height: 2.5;
}

.c-drop-menu__link {
  display: inline;
  text-decoration: none;
  color: #fff;
}

.c-drop-menu__link:hover {
  color: #be1010;
}

.c-drop-menu__logo img {
  width: 150px;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  padding: 2rem 1rem 3rem;
  background: #292929;
  color: #fff;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-footer__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.c-footer__logo {
  width: 100px;
  margin-bottom: 0.5rem;
}

.c-footer__address {
  line-height: 1.9;
}

.c-footer__address span {
  margin-left: 1.2rem;
}

.c-footer__address span:first-of-type {
  margin-left: 0;
}

.c-footer__left {
  font-size: 0.8rem;
}

.c-footer__right {
  margin-top: 1.5rem;
  font-size: 0.7rem;
  margin-left: auto;
}

@media (min-width: 768px) {
  .c-footer__right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: auto;
  }
}

.c-form {
  margin: 50px auto 0;
}

@media (min-width: 768px) {
  .c-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 800px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .c-form__group {
    width: 49%;
  }
}

.c-form__input {
  margin-top: 2.4rem;
}

.c-form__input:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-form__input {
    padding-right: 5rem;
  }
}

.c-form__input input {
  color: #191919;
  font-size: 0.8rem;
  font-weight: 700;
  width: 100%;
  padding-bottom: 0.8rem;
  background: transparent;
  border: none;
  border-bottom: 1px solid #191919;
}

.c-form__input input::placeholder {
  color: #191919;
}

input, textarea {
  outline: none;
}

.c-form__textarea {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .c-form__textarea {
    margin-top: 0;
  }
}

.c-form__textarea label {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 700;
  color: #191919;
  margin-bottom: 0.8rem;
}

.c-form__textarea textarea {
  color: #191919;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 0.5rem;
  resize: none;
  background: transparent;
  border: none;
  border: 1px solid #191919;
}

.c-form__accept {
  margin-top: 2rem;
}

.c-form__accept-explain {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  color: #191919;
  letter-spacing: 1px;
}

.c-form__label {
  display: flex;
  align-items: center;
}

.c-form__checkbox {
  margin-top: 5px;
  color: #191919;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.c-form__checkbox label {
  margin-left: 3px;
}

.c-form__checkbox a {
  color: #191919;
  font-weight: 700;
  text-decoration: none;
}

input[type="checkbox"] {
  position: absolute;
  z-index: -2222;
  left: -9999999px;
}

input[type="checkbox"]:checked + .c-form__label .c-form__real-checkbox:before {
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background: #191919;
}

.c-form__send {
  margin-top: 1.5rem;
  background: #191919;
  border: none;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 0;
  padding: 1rem 4rem;
  text-transform: uppercase;
}

.c-form__real-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #545454;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: relative;
  height: 65vh;
  background-image: url("../assets/images/header-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.c-header__box {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 15px;
}

.c-header__title {
  color: #fff;
  letter-spacing: 3px;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 3rem;
}

.c-header__subtitle {
  color: #fff;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.c-offer {
  padding: 0 2rem;
}

.c-offer--extra {
  background: #292929;
  color: #fff;
}

.c-offer__heading {
  font-size: 1.3rem;
  margin-top: 3rem;
  text-transform: uppercase;
  text-align: center;
}

.c-offer__content {
  text-align: center;
}

.c-offer__content-list {
  display: inline-block;
  text-align: left;
  margin: 2rem auto 4.5rem;
}

.c-offer__content-list li {
  list-style: disc;
}

.c-partners__content {
  display: flex;
  max-width: 500px;
  width: 100%;
  margin: 0 auto 3rem;
}

.c-pp {
  margin: 80px auto;
  padding: 0 15px;
}

.c-pp__item {
  margin-top: 3rem;
}

.c-referencje {
  padding: 0 15px 50px;
}

.c-referencje__category {
  background: none;
  border: 1px solid transparent;
  border-radius: 20px;
}

.c-referencje__category:focus {
  outline: none;
}

.c-referencje__category--active {
  border: 1px solid #191919;
  border-radius: 20px;
}

.c-referencje__switch {
  display: block;
  margin: 40px auto 0;
  text-align: center;
}

.c-referencje__wrapper {
  margin: 60px -15px 0;
}

.c-referencje__item {
  display: block;
  margin: 0 auto;
  max-width: 500px;
  margin-top: 4rem;
  padding: 1rem;
  text-decoration: none;
}

.c-referencje__hide {
  display: none;
}

.c-referencje__item-title {
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  margin-top: 1.6rem;
  margin-bottom: 0.5rem;
  color: #191919;
}

.c-referencje__item-subtitle {
  font-size: 0.9rem;
  font-style: italic;
  text-align: center;
  color: #989898;
}

.c-topbar {
  position: fixed;
  align-items: center;
  padding: 1.3rem;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background: #fff;
  z-index: 99999;
}

@media (min-width: 768px) {
  .c-topbar {
    padding: 1.3rem 9rem;
  }
}

.c-topbar__placeholder {
  height: 70px;
  display: block;
}

.c-topbar__logo {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0.85;
  color: #191919;
  text-decoration: none;
  width: 120px;
}

.c-topbar__logo:hover {
  color: #191919;
}

.c-topbar__left,
.c-topbar__right {
  display: flex;
  align-items: center;
}

.c-topbar__left {
  justify-content: space-between;
}

.c-topbar__hamburger {
  width: 2rem;
  height: 1.3rem;
  display: flex;
  flex-direction: column;
  margin-left: 0.85rem;
  cursor: pointer;
}

.c-topbar__hamburger span {
  display: block;
  height: 0.3rem;
  width: 100%;
  background: #191919;
  margin-top: 4px;
  border-radius: 5px;
}

.c-topbar__hamburger span:first-child {
  margin: 0;
}

.c-topbar__icon-round {
  border-radius: 50%;
  background: #312b30;
}

.c-topbar__lang-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 2rem;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

.c-topbar__lang-switch:hover {
  color: #fff;
}

.c-topbar__social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.5rem;
}

.c-topbar__social > img {
  width: 1rem;
  height: 1rem;
}

.c-why-us {
  padding: 0 15px 80px;
  background: #191919;
}

@media (min-width: 768px) {
  .c-why-us__content {
    display: flex;
    justify-content: space-between;
  }
}

.c-why-us__item {
  margin-top: 50px;
  width: 48%;
  margin: 50px auto 0;
}

.c-why-us__icon {
  width: 120px;
  margin: 0 auto;
}

.c-why-us__title {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 5px;
}

.c-why-us__description {
  color: #fff;
  text-align: center;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.slbOverlay, .slbWrapOuter {
  z-index: 9999999999999999;
}
