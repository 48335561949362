.c-about-us {
  padding: 0 15px 90px;
}

.c-about-us__content {
  max-width: 1000px;
  margin: 4rem auto 0;
}

.c-about-us__quote {
  line-height: 1.8;
  letter-spacing: 1px;
}

.c-about-us__faksmika {
  margin-top: 2rem;
  width: 300px;
}

.c-about-us__credentials {
  margin-top: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 1px;
}

.c-about-us__referencje {
  max-width: 1000px;
  margin: 4rem auto 0;
}

.c-about-us__referencje-icon {
  display: inline-block;
  width: 100px;
}

.c-about-us__referencje-link {
  display: flex;

  align-items: center;

  text-decoration: none;
}

.c-about-us__referencje-text {
  display: inline-block;

  font-size: 1.2rem;

  text-transform: uppercase;
  font-weight: 700;
  color: $color-black;
  margin-left: 10px;
  line-height: 0;
  letter-spacing: 1px;
}
