.c-dron-usage {
  background: $color-very-light-grey;
  padding: 0 15px;
}

.c-dron-usage__content {
  padding-bottom: 100px;

  @include bp(small) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    position: relative;
  }
}

.c-dron-usage__item {
  margin-top: 50px;

  @include bp(small) {
    margin-top: 100px;
    display: flex;

    width: 49%;
  }

  @include bp(medium) {
    width: 23%;
  }
}

.c-dron-usage__icon {
  max-width: 80px;

  @include bp(small) {
    max-width: 40px;
    width: 100%;
  }
}

.c-dron-usage__text {
  margin-top: 25px;
  font-size: 0.9rem;

  @include bp(small) {
    margin-top: 0;
    padding-left: 25px;
  }
}

.c-dron-usage__title {
  font-size: 1.2rem;
}
