.c-referencje {
  padding: 0 15px 50px;
}

.c-referencje__category {
  background: none;
  border: 1px solid transparent;
  border-radius: 20px;

  &:focus {
    outline: none;
  }

  &--active {
    border: 1px solid $color-black;
    border-radius: 20px;
  }
}

.c-referencje__switch {
  display: block;
  margin: 40px auto 0;
  text-align: center;
}

.c-referencje__wrapper {
  margin: 60px -15px 0;
}

.c-referencje__item {
  display: block;
  margin: 0 auto;
  max-width: 500px;

  margin-top: 4rem;
  padding: 1rem;
  
  text-decoration: none;
}

.c-referencje__hide {
  display: none;
}

.c-referencje__item-title {
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  margin-top: 1.6rem;
  margin-bottom: 0.5rem;
  color: $color-black;
}

.c-referencje__item-subtitle {
  font-size: 0.9rem;
  font-style: italic;
  text-align: center;
  color: $color-light-grey;
}
