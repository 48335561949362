/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding: 2rem 1rem 3rem;
  background: $color-bg-section;

  color: $color-white;

  text-transform: uppercase;
}

.c-footer__wrapper {
  @include bp(medium) {
    display: flex;

    justify-content: space-between;
  }
}

.c-footer__logo {
  width: 100px;
  margin-bottom: 0.5rem;
}

.c-footer__address {
  line-height: 1.9;

  span {
    margin-left: 1.2rem;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.c-footer__left {
  font-size: 0.8rem;
}

.c-footer__right {
  margin-top: 1.5rem;
  font-size: 0.7rem;
  margin-left: auto;

  @include bp(medium) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: auto;
  }
}