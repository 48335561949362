.c-offer {
  &--extra {
    background: $color-bg-section;
    color: $color-white;
  }
  padding: 0 2rem;
}

.c-offer__heading {
  font-size: 1.3rem;

  margin-top: 3rem;
  text-transform: uppercase;
  text-align: center;
}

.c-offer__content {
  text-align: center;
}

.c-offer__content-list {
  display: inline-block;
  text-align: left;

  margin: 2rem auto 4.5rem;

  li {
    list-style: disc;
  }
}