.o-heading {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
}

.o-heading__icon {
  width: 100px;
  display: inline-block;
  margin-bottom: 2rem;
}

.o-heading__title {
  font-size: 1.3rem;

  @include bp(small) {
    font-size: 1.3rem;
  }

  text-transform: uppercase;
  margin: 0;
  line-height: 1.3;

  &--white {
    color: $color-white;
  }
}

.o-heading__subheading {
  font-size: 1rem;
  max-width: 600px;
  line-height: 1.6;
  margin: 1rem auto 0;

  &--white {
    color: $color-white;
  }

  &--faded {
    color: $color-faded-grey;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}